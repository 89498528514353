import $ from 'jquery'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.0.0-beta.2): util.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});

  const winWidth = document.documentElement.clientWidth;


  if(winWidth < 760){

    $('a.colorbox img').unwrap();
  } else {

    $('a.colorbox').colorbox({maxHeight:"90%", maxWidth:"90%", rel:"colorbox"});
    $('a.colorbox-link').colorbox({maxHeight:"90%", maxWidth:"90%", rel:"colorbox-link"});

    $('a.colorbox').each(function(index){
      //console.log($(this).attr('mobile-url'));
      $(this).attr('href', $(this).attr('mobile-url'));
    });

  }

  $("#menu-toggle a").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $("#menu-toggle").toggleClass("toggled");
  });

  window.addEventListener("load", function(){
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#aa0000",
          "text": "#ffdddd"
        },
        "button": {
          "background": "#ff0000"
        }
      },
      "position": "bottom-right",
      "type": "opt-in",
      "content": {
      },
      onPopupOpen: function() {
        //console.log('<em>onPopupOpen()</em> called');
      },
      onPopupClose: function() {
        //console.log('<em>onPopupClose()</em> called');
      },
      onInitialise: function (status) {
        //console.log('<em>onInitialise()</em> called with status <em>'+status+'</em>');
      },
      onStatusChange: function(status) {
        //console.log('<em>onStatusChange()</em> called with status <em>'+status+'</em>');
        var choice = this.hasConsented() ?
        'enable cookies' : 'disable cookies';
        console.log(choice);
        if(choice === 'disable cookies'){
          //console.log('denied');
          var ajaxurl = 'co/delete.php',
          data =  {'action': 'deny'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        } else {
          //console.log('allowed');
          var ajaxurl = 'co/undelete-rivi.php',
          data =  {'action': 'consent'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        }
        window.location.reload(false);
      },
      onRevokeChoice: function() {
        //console.log('<em>onRevokeChoice()</em> called');
        delete_cookie('_gat');
        delete_cookie('_ga');
        delete_cookie('_gid');
        var ajaxurl = 'co/delete-rivi.php',
        data =  {'action': 'revoke'};
        $.post(ajaxurl, data, function (response) {
            // Response div goes here.
        });
        window.location.reload(false); 
      }
    })
  });


  var delete_cookie = function(name) {
      document.cookie = name + '=; Path=/; Domain=.about-rivington.co.uk; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  return Munki

})($)

export default Munki
